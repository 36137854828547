<template>
    <v-container grid-list-md pa-0 relative>
      <v-layout row wrap>
        <v-flex xs12 sm12 class="overlay">
          <v-card outlined class="primary--border">
            <v-card-title class="title">
              Admission Setting
              <v-spacer></v-spacer>
            </v-card-title>
            <v-divider></v-divider>
  
            <v-data-table
              :headers="headers"
              :items="items"
              :loading="loading"
              hide-default-footer
              :server-items-length="items.length"
            >
              <template v-slot:item="{ item, index }">
                <tr>
                  <td>{{ index + 1 }}</td>
                  <td>
                    <strong>{{ item.name }}</strong>
                  </td>
                  <td>
                    <v-select
                      class="mb-1 mt-1"
                      v-model="item.status"
                      :items="admission_status"
                      dense
                      outlined
                      @change="disabledUpdate=false"
                    />
                  </td>
                  <td>
                    <v-calendar-field
                      :id-val="'admission_from_date_' + index"
                      v-model="item.from_date"
                      style="margin-top: 6px;"
                      label="From Date"
                    />
                  </td>
                  <td>
                    <v-calendar-field
                      :id-val="'admission_to_date_' + index"
                      v-model="item.to_date"
                      style="margin-top: 6px;"
                      label="To Date"

                    />
                  </td>
                  <td>
                    <br />
                    <v-text-field
                      type="number"
                      @change="disabledUpdate=false"
                      autocomplete="off"
                      label="Entrance Fee"
                      v-model="item.entrance_fee"
                      name="entrance_fee"
                      outlined
                      dense
                    />
                  </td>
                </tr>
              </template>
            </v-data-table>
            <br />
            <edit-button
              @agree="updateAdmissionRecords"
              icon="dd"
              :disabled="disabledUpdate"
              :outlined="false"
              :large="true"
              :block="true"
              color="primary"
              label="Update Admission Records"
              :permission="true"
            />
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </template>
  
  <script>
  import Mixins from "@/library/Mixins";
  
  export default {
    mixins: [Mixins],
    data() {
      return {
        items: [],
        loading: false,
        disabledUpdate: true,
        headers: [
          { text: "SN", align: "left", value: "id", width: 10, sortable: false },
          { text: "Grade", align: "left", value: "grade", sortable: false },
          { text: "Status", align: "left", value: "status", sortable: false, width: 200 },
          { text: "From Date", align: "left", value: "from_date", sortable: false },
          { text: "To Date", align: "left", value: "to_date", sortable: false },
          { text: "Entrance Fee", align: "left", value: "entrance_fee", sortable: false },
        ],
        admission_status: [
          { text: "Active", value: "active" },
          { text: "Inactive", value: "inactive" },
        ],
      };
    },
    mounted() {
    //   this.getGrades();
      this.get();
    },
    computed: {
      allSelectedGenerateOn() {
        return this.items.flatMap((item) => item.generate_month_fixed);
      },
    },
    methods: {
      getGrades() {
        this.$rest
          .get("/api/grades?rowsPerPage=25&sortBy=rank&descending=false&roleWise=true")
          .then(({ data }) => {
            // this.items = data.data;
            this.items = data.data.map((item) => ({
                ...item,
                status: item.status ?? "active", // Default to "1" (Active) if status is undefined or null
            }));
          });
      },
      get(){
        this.$rest
          .get("/api/admission-setting?rowsPerPage=25&sortBy=rank&descending=false&device=web")
          .then(({ data }) => {
            this.items = data.data
          });
      },
      updateAdmissionRecords() {
        this.$rest.post(`api/admission-setting`, {
          params: this.items.map((item) => ({
            grade_id: item.id,
            grade_name: item.name,
            status: item.status,
            from_date: item.from_date ?? null,
            to_date: item.to_date ?? null,
            entrance_fee: item.entrance_fee ?? null
          })),
        }).then((res) => {
            this.get()
            this.$events.fire('notification', {
                message: res.data.message,
                status: 'success',
            });
        })
        // Implement API call or logic to update records here
      },
    },
  };
  </script>
  